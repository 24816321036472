<template>
  <v-pagination
    v-model="page"
    color="blue white--text"
    @input="$emit('update', $event)"
    :length="total"
    :total-visible="7"
  ></v-pagination>
</template>

<script>
export default {
  name: "Paginator",
  props: {
    total: Number,
  },
  data() {
    return {
      page: 1,
    };
  },
};
</script>

<style></style>
