<template>
  <div>
    <v-data-table
      v-if="!loading"
      hide-default-footer
      class="elevation-1"
      loading-text="Carregando..."
      :class="{ 'tabela-com-detalhes': tabelaComDetalhes }"
      :item-key="tabelaKey"
      :headers="headers"
      :items="items"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @click:row="$emit('clickRow', $event)"
    >
      <template v-slot:no-data>
        <div>Nenhum registro encontrado.</div>
      </template>

      <template
        v-for="slot in Object.keys($scopedSlots)"
        :slot="slot"
        slot-scope="scope"
      >
        <slot :name="slot" v-bind="scope" />
      </template>
    </v-data-table>

    <v-skeleton-loader v-else type="table"></v-skeleton-loader>

    <div
      v-if="pagination && (pagination.total > 1 && items.length)"
      class="text-center pt-2"
    >
      <Paginator
        :total="pagination.total"
        :key="keyPaginacao"
        @update="$emit('actualPage', $event)"
      />
    </div>
  </div>
</template>

<script>
import Paginator from '../../atoms/table/Paginator'

export default {
  components: {
    Paginator
  },

  props: {
    keyPaginacao: 0,
    tabelaKey: {
      required: false
    },
    headers: Array,
    itemsPerPage: {
      type: Number,
      default: 10
    },
    page: {
      type: Number,
      default: 1
    },
    items: {
      required: true,
      type: Array
    },
    pagination: Object,
    fieldsForm: Object,
    loading: Boolean,
    tabelaComDetalhes: Boolean,
  },
}
</script>
<style scoped>
  ::v-deep .v-data-table-header th {
    font-size: 1em !important;
  }

  ::v-deep .tabela-com-detalhes tbody tr {
    cursor:  pointer !important;
    transition: background 0.2s ease;
  }

  .icon {
    font-size: 1em !important;
  }
</style>
